import React from "react";
import { makeStyles, Typography, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  legal: {
    marginTop: theme.spacing(6),
  },
  link: {
    color: theme.palette.secondary.contrastText,
    fontSize: "1em",
    fontWeight: 300,
  },
}));

interface OwnProps {}

export const Legal: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.legal}>
      <Typography variant={"body1"}>
        <span>&copy;&nbsp;2020</span>&nbsp;|&nbsp;
        <Link className={classes.link} href={"https://enschedepromotie.nl/privacybeleid/"} target={"_blank"}>
          Privacybeleid
        </Link>
        &nbsp;|&nbsp;
        <Link className={classes.link} href={"https://enschedepromotie.nl/disclaimer/"} target={"_blank"}>
          Disclaimer
        </Link>
      </Typography>
    </div>
  );
}
