import {
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useAuth } from '../../../hooks/use-auth';
import { AuthState } from '../../services/auth';
import { PopupHeader } from '../popup-header';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    borderTop: '1px solid #ccc',
  },
  footerText: {
    fontSize: '0.8em',
  },
  close: {
    fontSize: '0.9em',
    marginTop: '-1em',
  },
  link: {
    fontSize: '0.9em',
    color: theme.palette.primary.main,
    paddingTop: theme.spacing(1),
    display: 'block',
    cursor: 'pointer',
    marginLeft: 15,
  },
}));

interface OwnProps {}

export const Registered: React.FC<OwnProps> = (props) => {
  const classes = useStyles();
  const auth = useAuth();

  const close = React.useCallback(() => {
    auth.setState(AuthState.SignIn);
  }, [auth]);

  return (
    <Dialog
      id="dialog-registration-complete"
      PaperProps={{
        style: {
          width: 480,
          borderRadius: 0,
          paddingBottom: 20,
        },
      }}
      open={auth.state === AuthState.RegisterSuccess}
      onClose={close}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent>
        <PopupHeader title={'Registeren voltooien'} handleClose={close} />
        <Typography variant="body2" paragraph>
          <small>Je account is succesvol aangemaakt!</small>
        </Typography>

        <Typography variant="body2">
          <small>
            Je ontvang van ons een bevestigingsmail waarmee je een wachtwoord kunt aanmaken om mee
            in te loggen.
          </small>
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-start' }}>
        <Link onClick={close} className={classes.link}>
          terug naar inloggen
        </Link>
      </DialogActions>
    </Dialog>
  );
};

export default Registered;
