import React from 'react';
import { makeStyles, Link, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  nav: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    lineHeight: '2em',
  },
  link: {
    color: theme.palette.secondary.contrastText,
    fontSize: '1em',
    fontWeight: 300,
  },
}));

interface OwnProps {}

export const NavEnschedePromotie: React.FC<OwnProps> = (props) => {
  const classes = useStyles();

  return (
    <nav className={classes.nav}>
      <Typography gutterBottom variant={'h5'}>
        Enschede Promotie
      </Typography>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/enschede-promotie/kernactiviteiten/'}
        target={'_blank'}
      >
        Kernactiviteiten
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/enschede-promotie/het-team/'}
        target={'_blank'}
      >
        Het team
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/enschede-promotie/tourist-info/'}
        target={'_blank'}
      >
        Tourist info
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/enschede-promotie/jaarverslag/'}
        target={'_blank'}
      >
        Jaarverslag
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/enschede-promotie/vacatures/'}
        target={'_blank'}
      >
        Vacatures
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/enschede-promotie/standplaats-aanvragen/'}
        target={'_blank'}
      >
        Standplaats aanvragen
      </Link>
      <Link
        className={classes.link}
        href={'https://enschedepromotie.nl/enschede-promotie/raad-van-toezicht/'}
        target={'_blank'}
      >
        Raad vab toezicht
      </Link>
    </nav>
  );
};
