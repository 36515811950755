import { Button, Checkbox, FormControlLabel, Link, Typography } from '@material-ui/core';
import { get } from 'lodash';
import { useCallback, useRef, useState } from 'react';

export const DownloadFileForm: React.FC<{ media: any }> = (props) => {
  // Setup download requirements
  const iframeRef = useRef<any | null>(null);
  const [agreed, setAgreed] = useState<boolean>(false);
  const downloadFile = useCallback(async () => {
    const baseUrl = window.location.protocol + '//' + window.location.host + '/';
    const dbID = get(props, 'media.details.file.databaseId', '');
    const downloadUrl = baseUrl + 'download.php?file=' + dbID;

    if (iframeRef.current) {
      iframeRef.current.src = downloadUrl;
    }
  }, [iframeRef, props]);

  return (
    <>
      <iframe title="Download" ref={iframeRef} style={{ display: 'none' }}></iframe>
      <div id="dialog-file-download">
        <Typography paragraph>
          Om een bestand te mogen downloaden dien je akkoord te gaan met onze{' '}
          <Link target="_blank" href="https://enschedepromotie.nl/algemene-voorwaarden-mediabank">
            Algemene Voorwaarden
          </Link>
        </Typography>

        <FormControlLabel
          control={<Checkbox checked={agreed} onChange={() => setAgreed(!agreed)} />}
          label="Ik ga akkoord met de geldende voorwaarden"
        />

        <br />
        <br />

        <Button
          id="download-dialog-btn"
          variant="contained"
          disabled={!agreed}
          color="primary"
          onClick={downloadFile}
        >
          Downloaden
        </Button>
      </div>
    </>
  );
};
